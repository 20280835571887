import { APIS } from 'constants/api';
import { imageUrlConvertToBase64, isMobileDevice } from 'utils';

export const showCaptureButton = (name: string) => {
	if (isMobileDevice()) {
		return true;
	} else {
		if (name !== 'Use My Camera') {
			return true;
		}
		return false;
	}
};

export const stopCamera = () => {
	const videoElement = document.getElementById('capture-video');
	if (videoElement instanceof HTMLVideoElement) {
		const stream = videoElement.srcObject;

		// Check if the stream exists
		if (stream instanceof MediaStream) {
			// Get all tracks from the stream
			const tracks = stream.getTracks();

			// Stop each track
			tracks.forEach((track: { stop: () => void }) => {
				track.stop();
			});

			videoElement.srcObject = null;
		}
	}
};

export const convertDataURIToBinary = (dataURI: string): Uint8Array => {
	// Function declaration with an input parameter 'dataURI'
	const BASE64_MARKER = ';base64,'; // Define a constant representing the marker used to identify base64 encoded data
	const base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length; // Find the index where base64 encoded data starts
	const base64 = dataURI.substring(base64Index); // Extract the base64 encoded portion of the data URI
	const raw = window.atob(base64); // Decode the base64 encoded string into raw binary data
	const rawLength = raw.length; // Get the length of the raw binary data
	const array = new Uint8Array(new ArrayBuffer(rawLength)); // Initialize a Uint8Array with the length of the raw binary data

	// Loop over each character of the raw binary data
	for (let i = 0; i < rawLength; i++) {
		array[i] = raw.charCodeAt(i); // Get the character code of each character and store it in the Uint8Array
	}

	return array; // Return the Uint8Array containing the binary data
};

export const isImageGlare = async (
	imgIntoBinary: any,
	glareUrl: any,
	setIsLoading: any
) => {
	const controller = new AbortController();
	setTimeout(() => {
		controller.abort('Aborted');
	}, 8000);
	try {
		return await fetch(glareUrl, {
			method: 'POST',
			headers: {
				'Content-Type': 'image/*',
			},
			body: imgIntoBinary,
			signal: controller.signal,
		});
	} catch (error) {
		setIsLoading(false);
		return { json: () => ({ Message: error }) };
	}
};

export const renderCaptureImage = () => {
	// Get the video element by its id and assert its type as HTMLVideoElement
	const video = document.getElementById('capture-video') as HTMLVideoElement;

	if (!video) {
		return null;
	}

	// Create a new canvas element
	const canvas = document.createElement('canvas');

	// Get the actual width and height of the video content
	const videoWidth = video.videoWidth;
	const videoHeight = video.videoHeight;

	// Define offset, padding, and _width variables for image capture
	const offset = 20;
	let _width = videoWidth - offset;
	const padding = 0;

	// Adjust _width if the window width is greater than or equal to 700 pixels
	if (window.innerWidth >= 700) {
		_width = videoWidth / 2;
	}

	// Calculate the width and height of the capture rectangle
	const rectWidth = _width + padding;
	const rectHeight = rectWidth / 1.5 + padding;

	// Calculate the position of the capture rectangle within the video frame
	const captureHeight = videoHeight / 2;
	const imageHeightHalf = rectHeight / 2;
	const x = videoWidth / 2 - rectWidth / 2;
	const y = captureHeight - imageHeightHalf;

	// Define source rectangle parameters for the drawImage method
	const sx = x;
	const sy = y;
	const sw = rectWidth;
	const sh = rectHeight;

	// Set canvas width and height
	canvas.width = rectWidth;
	canvas.height = rectHeight;

	// Get 2D rendering context of the canvas
	const context: any = canvas.getContext('2d');
	context.filter = 'brightness(1.1) contrast(1.0) saturate(1.1)';

	if (!context) {
		return null;
	}

	// Draw a portion of the video frame onto the canvas
	context.drawImage(video, sx, sy, sw, sh, 0, 0, sw, sh);

	// Convert the canvas content to a data URL using PNG format with compression quality 0.9
	const dataUrl = canvas.toDataURL('image/png', 1.0);
	return dataUrl;
};

export const imageRotateHandle = async (
	payload: any,
	rotatePostAPI: any,
	API_HOST: any,
	setCurrentImage: any
) => {
	const reponseRotateAPI = await rotatePostAPI(APIS.KYC_ROTATE, payload);

	if (reponseRotateAPI?.image?.length > 0) {
		const apiImageUrl = `${API_HOST}${APIS.KYC_OCR_IMAGE}/${reponseRotateAPI.image}`;
		const imageUrlConverted: any = await imageUrlConvertToBase64(apiImageUrl);

		setCurrentImage(imageUrlConverted);
		return imageUrlConverted;
	}
	return null;
	// eslint-disable-next-line react-hooks/exhaustive-deps
};

export const getVideoStream = async () => {
	const idealSize = {
		width: { ideal: 1920 },
		height: { ideal: 1080 },
	};

	const devices = await navigator.mediaDevices.enumerateDevices();
	let backCamera: any;
	devices?.forEach(device => {
		if (
			device.kind === 'videoinput' &&
			device.label.toLowerCase().includes('back')
		) {
			backCamera = device;
		}
	});
	const vedioStream: any = {};
	if (backCamera) {
		vedioStream.video = {
			deviceId: backCamera?.deviceId,
			...idealSize,
		};
	} else {
		vedioStream.video = {
			...idealSize,
		};
	}
	if (isMobileDevice()) {
		vedioStream.video = {
			facingMode: 'environment',
			...idealSize,
		};
	}
	return vedioStream;
};

export const drawRectangle = (canvasRef: any, drawPlaceholder: any) => {
	const video: any = document.getElementById('capture-video');
	const canvas = canvasRef.current;
	const context = canvas?.getContext('2d');
	const canvasWidth = canvas?.width ?? 0;
	const canvasHeight = canvas?.height ?? 0;
	// Draw the video frame
	context?.drawImage(video, 0, 0, canvasWidth, canvasHeight);
	const img = document.getElementById('myImage') as HTMLImageElement;

	// Overlay with black
	if (context) {
		const offset = 20;
		let width = window.innerWidth - offset;
		if (window.innerWidth >= 700) {
			width = window.innerWidth / 2;
		}
		const rectWidth = width;
		const rectHeight = rectWidth / 1.5;

		const captureHeight = canvasHeight / 2;
		const imageHeightHalf = rectHeight / 2;

		const x = canvasWidth / 2 - rectWidth / 2;
		const y = captureHeight - imageHeightHalf;

		context.globalAlpha = 0.8; // Adjust the alpha value as per your requirement for the black overlay opacity
		context.fillStyle = 'black';
		const blackTop = Math.abs(captureHeight - imageHeightHalf);

		const extraSpace = 0.3;
		context.fillRect(0, 0, canvasWidth, blackTop + extraSpace);

		const padding = (canvasWidth - rectWidth) / 2;
		const blackBottomY = captureHeight + imageHeightHalf;
		context.fillRect(0, blackBottomY - extraSpace, canvasWidth, blackTop + 20);

		context.fillRect(0, captureHeight - imageHeightHalf, padding, rectHeight);
		context.fillRect(
			canvasWidth - padding,
			captureHeight - imageHeightHalf,
			padding,
			rectHeight
		);

		(context as any).roundRect?.(x, y, rectWidth, rectHeight, 10);
		context.fillStyle = 'transparent';
		context.fill();
		context.lineWidth = 12;
		context.strokeStyle = 'black';
		context.stroke();
		context.drawImage(img, x, y, rectWidth, rectHeight);

		drawPlaceholder.current = {
			x,
			y,
			width: rectWidth,
			height: rectHeight,
		};

		context.globalAlpha = 0.8;
		context.drawImage(img, x, y, rectWidth, rectHeight);
		context.globalAlpha = 1.0; // Reset opacity back to default
	}
	requestAnimationFrame(() => drawRectangle(canvasRef, drawPlaceholder));
};

export function dataURItoBlob(dataURI: any, fileName: string) {
	// convert base64/URLEncoded data component to raw binary data held in a string
	let byteString;
	if (dataURI.split(',')[0].indexOf('base64') >= 0)
		byteString = atob(dataURI.split(',')[1]);
	else byteString = unescape(dataURI.split(',')[1]);
	// separate out the mime component
	const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
	// write the bytes of the string to a typed array
	const ia = new Uint8Array(byteString.length);
	for (let i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i);
	}
	const blob: any = new Blob([ia], { type: mimeString });
	blob.name = fileName;
	return blob;
}

export const handleUploadImage = (
	{ image, base64Image, key }: any,
	setFormProperties: any,
	fileName: string
) => {
	const isImageURL = /data:image/.test(image);
	if (image) {
		const base64 = isImageURL ? image : 'data:image/jpeg;base64,' + image;
		setFormProperties((content: any) => {
			const data: any = { ...content };
			const keyPayload = key + 'Payload';
			data[keyPayload] = dataURItoBlob(base64, fileName);
			data[key] = base64 ?? base64Image;
			data.btnDisabled = false;
			return data;
		});
	}
};
