import { useMemo } from 'react';

import {
	QrCodeGenerator,
	QrCodeGeneratorProps,
} from 'components/qr-code-generator';
import { QrDetails, QrVerification, useQrPage } from 'views/qr-page';

import './facial-qr.scss';

export const FacialQr = () => {
	// `qrProps` likely contains properties or methods related to QR code functionality for this page.
	const { qrProps } = useQrPage();

	// Extract title using useMemo to avoid recalculations
	const title = useMemo(() => QrDetails[QrVerification.Auth]?.title || '', []);

	return (
		<div className="Facial__Qr--wrapper">
			<div className="Facial__Qr--separate">OR</div>
			{title && <div className="Facial__Qr--title">{title}</div>}
			<QrCodeGenerator
				{...(qrProps(QrVerification.Auth) as QrCodeGeneratorProps)}
			/>
		</div>
	);
};
