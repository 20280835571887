import { useMemo } from 'react';

import { useNextStep, useSharedVariables } from 'hooks';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { SubmitBtnLoad } from 'views/selfie-camera/store';
import { AccessTokenState } from 'states';
import { useTokenSession } from 'hooks/token/token-session';
import { QrVerification, useQrPage } from 'views/qr-page';

export const useFaceRecognitionRequests = () => {
	const { sessionPayloadDetail, setSessionDetails, handleNext } = useNextStep();
	const setSubmitBtnLoad = useSetRecoilState(SubmitBtnLoad);
	const { code: sessionCode } = useRecoilValue(AccessTokenState);

	const { onboardingType } = useSharedVariables();
	const { postTokenSession } = useTokenSession();

	// Extract the `validateQrComplete` function from the `useQrPage` hook for QR validation logic.
	const { validateQrComplete } = useQrPage();

	const {
		_id: pipelineId,
		stepsId,
		userId,
		currentAction,
	} = useMemo(() => sessionPayloadDetail ?? {}, [sessionPayloadDetail]);

	const commonPayload = {
		pipelineId,
		userId,
		stepId: stepsId,
	};

	const submitFaceRecognition = async (selfieImage: string = '') => {
		setSubmitBtnLoad(true);
		if (onboardingType === 'complex') {
			const payload = {
				nodeId: currentAction._id ?? '',
				actions: [
					{
						id: '',
						data: {},
						selfieImage: selfieImage,
					},
				],
			};
			// COMPLEX_SESSION patch api
			const resp = await postTokenSession({ payload, code: sessionCode });

			// Return early if the QR validation is successful for the Auth verification type.
			if (validateQrComplete(resp, QrVerification.Auth)) return;

			if (resp) {
				const apiResponse = { ...resp };
				delete apiResponse.statusCode;
				setSessionDetails(prev => ({
					...prev,
					nodes: apiResponse,
					steps: [],
				}));
				setSubmitBtnLoad(false);
			}
		} else {
			const payload = {
				...commonPayload,
				actions: [
					{
						id: '',
						data: {},
						selfieImage: selfieImage,
					},
				],
			};
			// KYC_SESSION  patch api
			await postTokenSession({ payload, code: sessionCode });

			setSubmitBtnLoad(false);
			handleNext();
		}
	};

	return { submitFaceRecognition };
};
