import { FC, useEffect, useState } from 'react';
import { Image, SkeltonLoader } from '@storybook';
import { BodyNameKeyType, IDocumentDetails } from 'states';
import {
	IndentityVerificationObject,
	kycDocSvgIcons,
} from 'views/kyc/constants';
import { BetterPhotoTips } from 'components';
import './choose-verification.scss';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import {
	KycSelectedCountry,
	SelectedKycDocType,
	ShowKycSelectCountry,
} from '../../store';
import { EVENTS_TRACKING, timestamp, useTrackEvents } from 'helpers';

interface IChooseVerification {
	handleOnClickId: (key: BodyNameKeyType) => void;
}

export const ChooseVerification: FC<IChooseVerification> = ({
	handleOnClickId,
}) => {
	const [imageLoading, setImageLoading] = useState<boolean>(true);
	const resetKycSelectCountry = useResetRecoilState(ShowKycSelectCountry);
	const kycSelectedCountry = useRecoilValue(KycSelectedCountry);
	const [documentList, setDocumentList] = useState<IDocumentDetails[]>([]);
	const setSelectedKycDocType = useSetRecoilState(SelectedKycDocType);
	const { trackEvents } = useTrackEvents();

	useEffect(() => {
		const docList: any = kycSelectedCountry?.documents?.map((doc: any) => {
			if (doc.noOfSides === '1') {
				return { ...IndentityVerificationObject[1], ...doc };
			} else {
				return { ...IndentityVerificationObject[0], ...doc };
			}
		});
		if (docList?.length) {
			setDocumentList(docList);
		}
	}, [kycSelectedCountry]);
	return (
		<div className="identity-verification-new">
			<div className="identity-verification-new__left-container">
				<div className="identity-verification-new__left-container__title-container">
					<h5 className="identity-verification-new__left-container__title-container__label">
						<span
							className="identity-verification-new__left-container__title-container__label__back-btn"
							onClick={() => resetKycSelectCountry()}
						>
							<i className="ri-arrow-left-line" />
						</span>{' '}
						Identity Verification
					</h5>
					<h3 className="identity-verification-new__left-container__title-container__title">
						Choose a government-Issued photo ID
					</h3>
					<p className="identity-verification-new__left-container__title-container__sub-title">
						To proceed with identity verification, please select the type of
						government-issued photo ID you would like to use:
					</p>
				</div>
				<div className="identity-verification-new__left-container__doc-card-container">
					{documentList?.length ? (
						documentList?.map(({ name, key, ...rest }) => (
							<div
								key={key + name}
								onClick={() => {
									handleOnClickId(key);
									setSelectedKycDocType({ name, key, ...rest });
									trackEvents(EVENTS_TRACKING.KYC_DOCUMENT_CHOSEN, {
										doc_type: name,
										timestamp: timestamp,
									});
								}}
								className="identity-verification-new__left-container__doc-card-container__item"
							>
								{imageLoading && (
									<>
										<SkeltonLoader width="40px" height="40px" shape="circle" />
										<SkeltonLoader width="100%" height="30px" />
									</>
								)}
								<Image
									onLoad={() => setImageLoading(false)}
									fileName={
										(kycDocSvgIcons as any)[name] ?? kycDocSvgIcons['Default']
									}
									className={imageLoading ? 'd-none' : 'd-block animate-in-2'}
								/>
								{!imageLoading && (
									<span className="list-text animate-in-2">{name}</span>
								)}
							</div>
						))
					) : (
						<div className="identity-verification-new__left-container__doc-card-container__item no-data-found">
							No Documents Found!
						</div>
					)}
				</div>
				<BetterPhotoTips />
			</div>
			<div className="identity-verification-new__right-container">
				{imageLoading && <SkeltonLoader width="100%" height="220px" />}
				<Image
					onLoad={() => setImageLoading(false)}
					fileName="blank-id-card-updated.svg"
					width="100%"
					height="100%"
					className={imageLoading ? 'd-none' : 'd-block animate-in-2'}
				/>
			</div>
		</div>
	);
};
