import { Loader } from '@storybook';
import {
	useCallback,
	useEffect,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import {
	useRecoilState,
	useRecoilValue,
	useResetRecoilState,
	useSetRecoilState,
} from 'recoil';

import { BodyWrapper, LabelElement } from 'components';
import { useNextStep, useSharedVariables } from 'hooks';
import {
	EntityAvoidOptions,
	forceCallAPIForIFrame506b,
	nonOfAboveLabel,
} from 'views/accreditation/constants';
import {
	ChooseOption,
	InputForm,
	RadioSelect,
	UploadDocuments,
} from '../../components';
import { Header } from '../../components/header';
import {
	AccrdCustom506bUrlState,
	Accred506b,
	AccredQueAndAns,
	AccredSelectedKeyState,
	EntityAccredFormState,
	SelectedOptionState,
	SelectedUploadOptionKeyState,
	useAccreditationRequests,
} from '../../stores';

import { useNavigate } from 'react-router-dom';
import { UrlPathState } from 'states';
import { SimpliciSignWebComponent } from './accre-web-component';
import './accreditation-506b.scss';

const skipAccChoosePageIds = [
	'6696c97b1e621e36b9a95f4c',
	'66a249c9435b563397fc6505',
	'66a3cfb69c513577b8b7b49f',
	'66a3edd141731fed0cc3a279',
];

export const Accreditation506b = () => {
	//globel state
	const setSelectedOption = useSetRecoilState(SelectedOptionState);
	const [selectedAllLabels, setAllLabels] = useRecoilState(AccredQueAndAns);
	const resetAllLabels = useResetRecoilState(AccredQueAndAns);
	const resetSetSelectedAllLabel = useResetRecoilState(AccredQueAndAns);
	const [selectedKey, setSelectedKey] = useRecoilState(AccredSelectedKeyState);
	const resetSelectedKey = useResetRecoilState(AccredSelectedKeyState);
	const [formData, setFormData] = useRecoilState(EntityAccredFormState);
	const allList = useRecoilValue(Accred506b);
	const urlPath = useRecoilValue(UrlPathState);
	const setSelectedUploadKey = useSetRecoilState(SelectedUploadOptionKeyState);

	// local state
	const [currentUrl, setCurrentUrl] = useState<string | null>(null);
	const [remount, setRemount] = useState(true);
	const [isDocUploadLoading, setDocUploadLoading] = useState(false);
	const [loading, setIsLoading] = useState(false);
	const [selectedItems, setSelectedItems] = useState<any>();
	const handleFinishInvoked = useRef(false);
	// hooks
	const { handleNext: handleNextStep, sessionPayloadDetail } = useNextStep();
	const { requestURL, submitAccred, submitUploadDocuments, isHideNonOfAbove } =
		useAccreditationRequests();
	const navigate = useNavigate();
	const { onboardingType } = useSharedVariables();

	const onChange = useCallback((event: any) => {
		setSelectedItems(event);
	}, []);

	const {
		settings = [],
		title,
		subTitle,
		back = 'selectOption',
		type,
	} = useMemo(() => allList[selectedKey] ?? {}, [allList, selectedKey]);
	const accrdCustom506bUrl = useRecoilValue(AccrdCustom506bUrlState);

	const { accreditationDocument, accreditationType } = useMemo(
		() => (sessionPayloadDetail as any).currentAction?.metadata ?? {},
		[sessionPayloadDetail]
	);

	const filteredSettings = useMemo(
		() =>
			isHideNonOfAbove
				? settings?.filter(
						(setting: { label: string }) => setting.label !== nonOfAboveLabel
					) ?? []
				: settings,
		[isHideNonOfAbove, settings]
	);

	const {
		iframe,
		value,
		currentUrl: requestUrlForComplexGeneric,
	} = useMemo(
		() => (sessionPayloadDetail as any).currentAction ?? {},
		[sessionPayloadDetail]
	);

	const { label, url: customUrl } = useMemo(
		() => accreditationType ?? {},
		[accreditationType]
	);

	useEffect(() => {
		if (accreditationDocument?.value === 'customUpload') {
			setCurrentUrl(customUrl);
		}
		if (
			onboardingType === 'complex' &&
			accreditationDocument?.value === 'customUpload'
		) {
			setCurrentUrl(accrdCustom506bUrl?.url);
		}
	}, [
		accrdCustom506bUrl?.url,
		accreditationDocument?.value,
		customUrl,
		onboardingType,
	]);

	const handleOnSubmit = useCallback(async () => {
		await submitAccred();
	}, [submitAccred]);

	const forceCallSignDocApi = useCallback(async () => {
		setIsLoading(true);
		if (iframe && value[0]?.url) {
			setCurrentUrl(value[0].url);
		} else if (
			onboardingType === 'complex' &&
			accreditationDocument?.value !== 'customUpload'
		) {
			setCurrentUrl(requestUrlForComplexGeneric || null);
			await requestURL((res: string) => setCurrentUrl(res));
		} else {
			await requestURL((res: string) => setCurrentUrl(res));
		}
		setIsLoading(false);
	}, [
		accreditationDocument?.value,
		iframe,
		onboardingType,
		requestURL,
		requestUrlForComplexGeneric,
		value,
	]);

	//passing item as we will call this function in choose option
	const handleNext = useCallback(
		(item?: any) => {
			setAllLabels((prev: any) => {
				const prevData = JSON.parse(JSON.stringify(prev));
				const index = prevData.findIndex(
					(item: any) => item.key === selectedKey
				);
				if (index !== -1) {
					prevData.splice(index, 1, {
						key: selectedKey,
						answers: item?.label ?? selectedItems?.label,
						questions: title ?? '',
					});
				} else {
					prevData.push({
						key: selectedKey,
						answers: item?.label ?? selectedItems?.label,
						questions: title ?? '',
					});
				}
				return [...prevData];
			});
			if (
				forceCallAPIForIFrame506b.includes(selectedItems?.key) ||
				(selectedItems?.key === EntityAvoidOptions.DescribeEntity &&
					item.key === 'entityAccreditationVerify')
			) {
				return forceCallSignDocApi();
			}
			//need to remount force fully to select component
			setRemount(false);
			setTimeout(() => setRemount(true), 0);
			const id = item?.id || selectedItems?.id || '';
			const key = item?.key || selectedItems?.key || '';
			setSelectedKey(key);
			setSelectedUploadKey(`${id}_${key}`);
			return null;
		},
		[
			forceCallSignDocApi,
			selectedItems,
			selectedKey,
			setAllLabels,
			setSelectedKey,
			setSelectedUploadKey,
			title,
		]
	);

	const handleBack = useCallback(() => {
		setAllLabels((prev: any) => {
			const prevData = JSON.parse(JSON.stringify(prev));
			const filteredData =
				prevData?.map((item: { key: string }) =>
					item.key === back ? { ...item, isDeleted: true } : item
				) ?? [];
			return [...filteredData];
		});
		setSelectedKey(back);
		//need to remount force fully to select component
		setRemount(false);
		setTimeout(() => setRemount(true), 0);
	}, [back, setAllLabels, setSelectedKey]);

	//get default value if selected value is not present then return first index of array else return previous selected object of Array

	const getDefaultValue = useMemo(() => {
		const index = selectedAllLabels?.find(
			(item: any) => item?.key === selectedKey
		);
		const data = settings?.find((item: any) => item?.id === index?.id);
		if (data) return data;
		else return settings[0];
	}, [selectedAllLabels, selectedKey, settings]);

	const handleChooseOption = useCallback(
		(event: any) => {
			resetSetSelectedAllLabel();
			onChange(event);
			handleNext(event);
		},
		[handleNext, onChange, resetSetSelectedAllLabel]
	);

	useEffect(() => {
		if (
			selectedItems?.type === 'radio' &&
			selectedKey !== EntityAvoidOptions.DescribeEntity
		) {
			setSelectedOption(`${selectedItems?.id ?? 1}`);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedItems]);

	useEffect(() => {
		//setting up default selected Value when setting will change
		setSelectedItems(getDefaultValue);
	}, [getDefaultValue, settings]);

	const skipChoosePage = useMemo(() => {
		if (
			skipAccChoosePageIds.includes(sessionPayloadDetail.pipelineId as string)
		) {
			// Skip Accreditation 1st page if user coming from Individual
			if (
				['Individual', 'Business'].includes(
					sessionPayloadDetail?.identityType as string
				)
			)
				return true;
		}
		return false;
	}, [sessionPayloadDetail?.identityType, sessionPayloadDetail?.pipelineId]);

	useLayoutEffect(() => {
		//the user should be directly redirected to the accreditation individual questions
		if (
			skipAccChoosePageIds.includes(sessionPayloadDetail.pipelineId as string)
		) {
			// Skip Accreditation 1st page if user coming from Individual
			if (sessionPayloadDetail?.identityType === 'Individual') {
				handleChooseOption(settings[0]);
				return;
			}
			// Skip Accreditation 1st page if user coming from Business Flow
			if (sessionPayloadDetail?.identityType === 'Business') {
				handleChooseOption(settings[1]);
				return;
			}
			handleChooseOption(settings[0]);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sessionPayloadDetail?.identityType]);

	const handleUploadDocuments = useCallback(async () => {
		setDocUploadLoading(true);
		const status = await submitUploadDocuments();
		setDocUploadLoading(!status);
		handleNextStep();
	}, [handleNextStep, submitUploadDocuments]);

	const handleChange = useCallback(
		(e: any) => {
			const { name, value } = e.target ?? {};
			setFormData((prev: any) => {
				const payload = {
					[name]: value,
				};
				return { ...prev, ...payload };
			});
		},
		[setFormData]
	);

	const getValue = useCallback((input: string) => {
		if (input && input.trim() !== '') return true;
		else return false;
	}, []);

	const getDisable = useMemo(() => {
		const { name, entityName, designation, describe } = formData ?? {};
		if (selectedKey === 'entityAccreditationForm') {
			if (getValue(name) && getValue(entityName) && getValue(designation))
				return false;
			return true;
		}
		if (selectedKey === EntityAvoidOptions.DescribeEntity) {
			if (getValue(describe)) return false;
			return true;
		}
		return false;
	}, [formData, getValue, selectedKey]);

	const renderComponent = useMemo(() => {
		switch (type) {
			case 'choose-option':
				return (
					<ChooseOption settings={settings} handleClick={handleChooseOption} />
				);
			case 'radio-select':
				return (
					remount && (
						<RadioSelect
							defaultValue={getDefaultValue}
							onChange={onChange}
							settings={filteredSettings}
							handleNext={handleNext}
							handleBack={handleBack}
						/>
					)
				);
			case 'upload-document':
				return (
					<UploadDocuments
						handleBack={handleBack}
						handleNext={handleUploadDocuments}
						isLoading={isDocUploadLoading}
					/>
				);
			case 'input-form':
				return (
					<InputForm
						settings={settings}
						formData={formData}
						handleNext={handleNext}
						handleBack={handleBack}
						handleChange={handleChange}
						isDisabled={getDisable}
						skipChoosePage={skipChoosePage}
					/>
				);
			default:
				return <div></div>;
		}
	}, [
		type,
		settings,
		handleChooseOption,
		remount,
		getDefaultValue,
		onChange,
		handleNext,
		handleBack,
		handleUploadDocuments,
		handleChange,
		getDisable,
		formData,
		isDocUploadLoading,
		filteredSettings,
		skipChoosePage,
	]);

	(window as any).handleFinish = async () => {
		// eslint-disable-next-line no-console
		console.log('triggered simplici sccreditation');
		if (!handleFinishInvoked.current) {
			handleFinishInvoked.current = true;
			navigate(urlPath);
			setIsLoading(true);
			setCurrentUrl(null);
			await handleOnSubmit();
			handleNextStep();
			handleFinishInvoked.current = false;
			setTimeout(() => {
				setIsLoading(false);
				resetAllLabels();
				resetSelectedKey();
			}, 2000);
		}
	};

	const renderMainComponent = useMemo(() => {
		if (currentUrl) {
			return (
				<div className="iframe-wrapper iframe-wrapper-506b">
					<SimpliciSignWebComponent
						currentUrl={currentUrl}
						submit_esign="handleFinish"
					/>
				</div>
			);
		}
		return renderComponent;
	}, [currentUrl, renderComponent]);

	const labelElement = useMemo(
		() =>
			!currentUrl ? <LabelElement text={label ?? 'Accreditation'} /> : <></>,
		[label, currentUrl]
	);

	return loading ? (
		<div className="loader-506b">
			<Loader />
		</div>
	) : (
		<BodyWrapper
			label={labelElement}
			headerElement={
				!currentUrl ? <Header title={title} subTitle={subTitle} /> : <></>
			}
			bodyElement={<>{renderMainComponent}</>}
		/>
	);
};
