import { QrCodeGenerator } from 'components/qr-code-generator';
import { IQrVerificationTypes, useQrPage } from '../../stores';

import './qr.scss';
import { FC, useMemo } from 'react';
import { QrDetails } from '../../constant';

interface IQr {
	verificationType: IQrVerificationTypes;
}

export const Qr: FC<IQr> = ({ verificationType }) => {
	// Destructure the `continueWithDesktop` function from `useQrPage` hook
	// This function likely handles Auth process continuation for desktop users
	const { continueWithDesktop, qrProps } = useQrPage();

	const {
		title = '',
		description = '',
		steps = [],
		tips = [],
	} = useMemo(() => QrDetails[verificationType] ?? {}, [verificationType]);

	return (
		<div className="Qr--wrapper">
			{/* Wrapper for the entire QR code section */}
			<div className="Qr--details_wrapper">
				{/* Section containing information about the Auth process */}
				<div className="Qr--info_details">
					{/* Title for the Auth process */}
					<div className="Qr--info_title">{title}</div>
					{/* Description providing details for Auth identity verification */}

					<div className="Qr--info_description">{description}</div>
				</div>

				{/* Steps section explaining how to complete the verification */}
				{!!steps?.length && (
					<div className="Qr--steps">
						{/* Title for the steps */}
						<div className="Qr--steps_title">Steps</div>
						{/* Unordered list of steps for the Auth process */}
						<ul className="Qr--steps_list">
							{steps.map(({ value }) => (
								<li key={value} className="Qr--steps_list_item">
									{value}
								</li>
							))}
						</ul>
					</div>
				)}

				{/* Tips section providing advice for better document photos */}
				{!!tips?.length && (
					<div className="Qr--tips">
						{/* Header containing an icon and title for the tips section */}
						<div className="Qr--tips_header">
							<i className="ri-flashlight-fill Qr--tips-icon" />
							<div className="Qr--tips_title">Tips for a better photo:</div>
						</div>
						{/* Unordered list of tips to ensure better quality photos */}
						<ul className="Qr--tips_list">
							{tips.map(({ value }) => (
								<li key={value} className="Qr--tips_list_item">
									{value}
								</li>
							))}
						</ul>
					</div>
				)}
			</div>

			{/* Section for QR code scanning */}
			<div className="Qr--qr_wrapper">
				{/* Header text instructing the user to scan the QR code */}
				<div className="Qr--qr_header">
					Scan the QR code using your smartphone camera.
				</div>
				{/* Wrapper for the QR image */}
				<div className="Qr--qr_image_wrapper">
					<QrCodeGenerator
						{...(qrProps(verificationType) as { value: string })}
					/>
				</div>
				{/* Footer with additional options like 'Continue using Desktop' */}
				<div className="Qr--qr_footer">
					<div className="Qr--qr_mobile">No app installation is required</div>
					<div className="Qr--qr_or">OR</div>
					<div onClick={continueWithDesktop} className="Qr--qr_desktop">
						Continue using Desktop
					</div>
				</div>
			</div>
		</div>
	);
};
