import { FC, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { Qr, QrLoader, QrSuccess } from './components';
import { QrLoadingMessage, QrVerification } from './constant';
import {
	IQrPageNavigate,
	IQrVerificationTypes,
	QrPageNavigateState,
} from './stores';
import { LivenessInformation } from 'views/facial-enroll/components/liveness-information';

export interface IQrPage {
	navigatePage?: IQrPageNavigate;
	verificationType: IQrVerificationTypes;
}

export const QrPage: FC<IQrPage> = ({ verificationType }) => {
	// Retrieve the current page navigation state for the QR flow
	const qrNavigate = useRecoilValue(QrPageNavigateState);

	// Memoize the component rendering logic based on `qrNavigate` state
	const renderAuthQrPage = useMemo(() => {
		switch (qrNavigate) {
			// Display the main QR page component
			case 'qr-page':
				if (verificationType === QrVerification.Auth) {
					return <LivenessInformation />;
				} else {
					return <Qr verificationType={verificationType} />;
				}

			// Display success page with default success status
			case 'qr-success':
				return <QrSuccess verificationType={verificationType} />;

			// Display success page with redirect status
			case 'qr-success-next':
				return (
					<QrSuccess status="redirect" verificationType={verificationType} />
				);

			// Display success page with reload status
			case 'qr-success-reload':
				return (
					<QrSuccess status="reload" verificationType={verificationType} />
				);

			// Display loading screen with a loading message
			case 'qr-loader':
				return (
					<div className="loading-steps">
						<QrLoader
							description={QrLoadingMessage[verificationType as 'auth']}
						/>
					</div>
				);

			// Display success page with in-process status
			case 'in-process':
				return (
					<QrSuccess status="in-process" verificationType={verificationType} />
				);

			// Display success page with terminated status
			case 'terminated':
				return (
					<QrSuccess status="terminated" verificationType={verificationType} />
				);

			// Default case: show the main QR page if no matching case is found
			default:
				return <Qr verificationType={verificationType} />;
		}
	}, [qrNavigate, verificationType]);

	// Render the appropriate component based on the `qrNavigate` state
	return renderAuthQrPage;
};
