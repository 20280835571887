import { SurveyQuestionElementBase } from 'survey-react-ui';
import { useLocation } from 'hooks';
import { useState } from 'react';
import { AutoFillLocation } from '@storybook/auto-fil-input-location';

const ExtendedCustomAddressPicker = ({
	style,
	value,
	handleUpdateDetails,
	handleChange,
}: any) => {
	const [isLoading, setIsLoading] = useState(false);
	const { getAddress } = useLocation();

	const handleCurrentLocation = async () => {
		setIsLoading(true);
		await getAddress((data: any) => {
			handleUpdateDetails({
				...data,
				enteredAddress: `${data?.city}, ${data?.state}, ${data?.country}`,
			});
		});
		setIsLoading(false);
	};
	return (
		<div style={style}>
			<AutoFillLocation
				handleOnChange={handleChange}
				value={value}
				onSuccess={handleUpdateDetails}
				handleOnClickLocate={handleCurrentLocation}
				isLocateLoaded={isLoading}
			/>
		</div>
	);
};

export class CustomAddressPicker extends SurveyQuestionElementBase {
	location: any;
	constructor(props: any) {
		super(props);
		this.state = {
			value: (this.question as any)?.jsonObj?.defaultValue ?? null,
		};
	}
	get question() {
		return this.questionBase;
	}
	get value() {
		return this.question?.value;
	}
	get disableAlpha() {
		return this.question.disableAlpha;
	}
	get type() {
		return this.question.colorPickerType;
	}

	handleChange = (e: any) => {
		this.setState((prev: any) => ({ ...prev, value: e.target.value }));
	};

	handleUpdateDetails = (data: any) => {
		this.question.value = data;
		this.setState({ value: data.enteredAddress });
	};
	// Support the read-only and design modes
	get style() {
		return this.question.getPropertyValue('readOnly') ||
			this.question.isDesignMode
			? { pointerEvents: 'none' }
			: undefined;
	}

	renderElement() {
		return (
			<ExtendedCustomAddressPicker
				styles={this.style}
				value={this.state.value ?? this.question?.value?.enteredAddress}
				handleUpdateDetails={this.handleUpdateDetails}
				handleChange={this.handleChange}
			/>
		);
	}
}
