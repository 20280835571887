export const SurveyQuestionType = {
	MATRIX_DROPDOWN: 'matrixdropdown', // Type for matrix dropdown questions
};

export const SurveyQuestionName = {
	BENEFICIAL_OWNER: 'beneficialOwner', // Name for the beneficial owner question
	AUTHORIZED_SIGNER: 'authorizedSigner', // Name for the authorized signer question
};

export const SurveyMatrixColumnName = {
	SSN: 'ssn', // Column name for social security number
	ID_NUMBER: 'idNumber', // Column name for ID number
	NAME: 'name', // Column name for representative's name
	DATE_OF_BIRTH: 'dateOfBirth', // Column name for date of birth
	DOB: 'dob', // Alternate column name for date of birth
	TITLE: 'title', // Column name for title
	DESIGNATION: 'designation', // Column name for designation/role
	EMAIL: 'email', // Column name for email address
};

export const EntityVerificationTitle = 'Please select your identity type';
