import { IQrPageNavigate, IQrProcessState } from './types';

import { atom } from 'recoil';
import { isDesktopDevice } from 'utils/device';

// Atom to control the visibility of the QR page based on device type
export const ShowQrPageState = atom<boolean>({
	key: 'show-qr-page-state-key', // Unique key for the Recoil atom
	default: isDesktopDevice(), // Default to true if on a desktop device
});

// Atom to manage the current navigation state for the QR page
export const QrPageNavigateState = atom<IQrPageNavigate>({
	key: 'page-navigate-state-key', // Unique key for the Recoil atom
	default: 'qr-page', // Initial state set to 'qr-page'
});

// Atom to manage QR process state globally
export const QrProcessState = atom<IQrProcessState>({
	key: 'qr-process-state-key', // Unique key for Recoil atom
	default: {
		// Default state values
		role: null, // No role assigned initially
		status: null, // No status assigned initially
		message: '', // Empty message by default
		connectionId: '', // No connection ID initially
	},
});
