import { FC, ChangeEvent, useCallback, useEffect } from 'react';

import { InvestorsCountInput } from './components';
import { InvestorTable } from './components/investor-table';
import { useRecoilState } from 'recoil';
import { InvestorsCount, InvestorsDetails, useInvestorDetails } from './store';

import './investor-details-form.scss';
import { useNotification } from 'hooks';
import { IInvestor } from './constant';
import { toast } from 'react-toastify';
interface IInvestorForm {
	updateInvestorCallback: (data: any) => void;
	defaultValue: any;
}

export const InvestorForm: FC<IInvestorForm> = ({
	updateInvestorCallback,
	defaultValue,
}) => {
	const [investors, setInvestors] = useRecoilState(InvestorsCount);
	const [investorsDetails, setInvestorsDetails] =
		useRecoilState(InvestorsDetails);
	const { disableExtraRows } = useInvestorDetails();
	const { errorNotification } = useNotification();

	const handleChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			const { value } = e.target;
			const isValid = /^(?:|undefined|0|[1-9]|[1-4][0-9]|50)$/.test(value);

			if (isValid) {
				setInvestors(value);
			} else if (investors !== value) {
				toast.dismiss();
				toast.clearWaitingQueue();
				// Check if the current state is already the invalid value
				errorNotification(
					'Owners/shareholders can not be more than 50 and less than 0'
				);
			}
		},
		[errorNotification, setInvestors, investors]
	);

	useEffect(() => {
		if (defaultValue?.count && defaultValue?.investorsDetails?.length) {
			setInvestors(defaultValue?.count ?? 1);
			setInvestorsDetails(defaultValue?.investorsDetails ?? []);
		}
	}, [defaultValue, setInvestors, setInvestorsDetails]);

	useEffect(() => {
		if (investorsDetails) {
			const updatedInvestorsDetails = investorsDetails.filter(
				(investor: IInvestor) => !investor.disableRow
			);
			if (+investors > 1 && updatedInvestorsDetails.length) {
				updateInvestorCallback({
					count: investors,
					investorsDetails: updatedInvestorsDetails,
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [investorsDetails]);

	useEffect(() => {
		if (investors) {
			disableExtraRows();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [investors]);

	return (
		<div className="investor-details__form-wrapper">
			<InvestorsCountInput
				handleChange={handleChange}
				value={investors as any}
				type="number"
				placeholder="please enter how many Shareholders/Owners do you have ?"
				label="How many Shareholders/Owners do you have ?"
			/>
			<InvestorTable />
		</div>
	);
};
