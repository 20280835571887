import type { FC } from 'react';

import { RecoilRoot } from 'recoil';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import { App } from './App';

import './index.scss';

// const root = ReactDOMClient.createRoot(
// 	document.getElementById('root') as HTMLElement
// );

// Replace with your GA4 Measurement ID
const GA_MEASUREMENT_ID = 'G-DFZ2ND2B4Z';

// Add the GA4 script to the document head
const initializeGA = () => {
	const script = document.createElement('script');
	script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`;
	script.async = true;
	document.head.appendChild(script);

	script.onload = () => {
		// Initialize GA4 after the script has loaded
		(window as any).dataLayer = (window as any).dataLayer || [];
		function gtag(...args: any[]) {
			(window as any).dataLayer.push(args);
		}
		gtag('js', new Date());
		gtag('config', GA_MEASUREMENT_ID);
		gtag('config', GA_MEASUREMENT_ID, {
			debug_mode: true,
		});
	};
};

// Initialize GA
initializeGA();

const Root: FC<any> = (props: any) => {
	return (
		<RecoilRoot>
			<Router>
				<App {...props} />
			</Router>
		</RecoilRoot>
	);
};
class Onboarding extends HTMLElement {
	connectedCallback() {
		const props: Record<string, any> = {};
		const attributes = this.getAttributeNames();

		attributes.forEach(attr => {
			const propData = this.getAttribute(attr);
			if (
				window[propData as any] &&
				typeof window[propData as any] === 'function'
			) {
				props[attr] = window[propData as any];
			} else {
				props[attr] = propData;
			}
		});

		// eslint-disable-next-line react/no-deprecated
		ReactDOM.render(<Root {...props} />, this);
	}

	disconnectedCallback() {
		// eslint-disable-next-line react/no-deprecated
		ReactDOM.unmountComponentAtNode(this);
	}

	static get observedAttributes() {
		return ['session_id', 'code', 'env'];
	}

	attributeChangedCallback(_: string, oldValue: any, newValue: any) {
		if (newValue !== oldValue) {
			this.connectedCallback();
		}
	}
}

customElements.define('simplici-onboarding', Onboarding);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
