import { useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { KycSteps } from 'constants/common';
import { CheckDefense } from 'views/check-defense';
import { ExistingSessionsState, useExistingSession } from 'views/exist-session';
import {
	AddressDetails,
	DocumentVerificationWrapper,
	KycLoader,
	ProvideSSNNumber,
} from './components';
import {
	AddressNotFetchedState,
	IDMeritStepState,
	IsBackupKycState,
	KycLoaderState,
	useKycRequests,
} from './stores';
import { QrVerification, useQrPage } from 'views/qr-page';

import './kyc.scss';

export const KYC = () => {
	//globle states
	const [currentStep, setCurrentStep] = useRecoilState(IDMeritStepState);
	const setIsKYCExist = useSetRecoilState(ExistingSessionsState);
	const isAddressNotFetched = useRecoilValue(AddressNotFetchedState);

	// local states
	const [isBackupKYC] = useRecoilState(IsBackupKycState);
	const kycLoaders = useRecoilValue(KycLoaderState);

	// hooks
	const { getExistingId } = useExistingSession();
	const { currentAction } = useKycRequests();

	// Destructure functions to manage QR page rendering and connection handling.
	const { renderQrPages, handleCreateConnection } = useQrPage();

	useEffect(() => {
		// Initiates the WebSocket connection when the component mounts
		handleCreateConnection();

		// Adds an event listener to handle the 'beforeunload' event
		const handleBeforeUnload = (e: BeforeUnloadEvent) => {
			// Prevents the page from closing immediately and displays a confirmation dialog
			// Note: Custom messages aren't displayed in most modern browsers for security reasons
			e.preventDefault();
			e.returnValue = ''; // Required for some browsers to show the confirmation dialog
		};

		// Register the 'beforeunload' event listener
		window.addEventListener('beforeunload', handleBeforeUnload);

		// Cleanup: Remove the event listener when the component unmounts
		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (getExistingId('kyc')) {
			setIsKYCExist(pre => ({ ...pre, kyc: true }));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getExistingId]);

	useEffect(() => {
		if (currentAction?.metadata?.SSN ?? currentAction?.metadata?.ssn) {
			setCurrentStep(KycSteps.Ssn!);
		} else if (
			currentAction?.metadata?.addressVerification &&
			isAddressNotFetched
		) {
			setCurrentStep(KycSteps.Address!);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Memoize QR content for KYC verification to optimize rendering.
	const qrContent = useMemo(
		() => renderQrPages(QrVerification.Kyc),
		[renderQrPages]
	);

	const getComponent = useMemo(() => {
		// Return the QR content if it exists.
		if (qrContent !== null) {
			return qrContent;
		}

		if (kycLoaders) {
			return <KycLoader />;
		}
		if (isBackupKYC) {
			if (currentStep === KycSteps.Ssn) return <ProvideSSNNumber />;
			if (currentStep === KycSteps.Address) return <AddressDetails />;
			if (currentStep === KycSteps.CheckScan) return <CheckDefense />;
			return <KycLoader />;
		}
		if (currentStep !== KycSteps.Ssn) {
			return <DocumentVerificationWrapper />;
		}
		return null;
	}, [qrContent, kycLoaders, isBackupKYC, currentStep]);

	return getComponent;
};
