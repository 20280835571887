import { IOption, Loader, ReactDropdown } from '@storybook';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSharedVariables } from 'hooks';
import useFetchWithToken from 'hooks/use-fetch-with-token/use-fetch-with-token';
import { APIS } from 'constants/api';
import { THE_KYB_PROVIDER_TOKEN } from 'views/kyb/constants';
import { useRecoilState } from 'recoil';
import { CountryStateListState } from 'views/kyb/stores';
import './countery-dropdown.scss';

interface ICountryForm {
	handleUpdateCountryDetails: (data: any) => void;
	defaultValue?: Record<string, string>;
}

export const CounteryDropdown: FC<ICountryForm> = ({
	handleUpdateCountryDetails,
	defaultValue,
}) => {
	const [selectedCountry, setSelectedCountry] = useState<IOption | null>(null);
	const [selectedState, setSelectedState] = useState<IOption | null>(null);
	const [isLoadingContries, setLoadingContries] = useState(false);
	const [countryList, setCountryList] = useRecoilState(CountryStateListState);

	const { fetchData } = useFetchWithToken();
	const { envHost } = useSharedVariables();

	useEffect(() => {
		if (!defaultValue) return;
		let countries = null;
		if (defaultValue?.country) {
			countries = (countryList ?? [])?.find(country =>
				[country.iso3?.toLowerCase(), country.iso2?.toLowerCase()]?.includes(
					defaultValue?.country?.toLowerCase() as string
				)
			);
			setSelectedCountry({
				label: countries?.name ?? defaultValue?.country ?? '',
				value: defaultValue?.country ?? '',
			});
		}

		if (defaultValue?.state) {
			const stateLabel = (countries?.states ?? [])?.find(
				(state: Record<string, string>) =>
					state.state_code?.toLowerCase() === defaultValue?.state?.toLowerCase()
			);
			setSelectedState({
				label: stateLabel?.name ?? defaultValue?.state ?? '',
				value: defaultValue?.state ?? '',
			});
		}
	}, [countryList, defaultValue]);

	const formatValue = (country?: string): string => {
		return (country?.replace(/_/g, ' ') ?? '--')
			.toLowerCase()
			.replace(/\b\w/g, (char: string) => char.toUpperCase());
	};

	const fetchCountryData = useCallback(async () => {
		setLoadingContries(true);
		const { data } = await fetchData(
			APIS.COUNTRY_LIST,
			THE_KYB_PROVIDER_TOKEN[envHost as keyof typeof THE_KYB_PROVIDER_TOKEN]
		);
		setCountryList(data);
		setLoadingContries(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleOnChange = useCallback((event: any) => {
		setSelectedState(null);
		setSelectedCountry(event);
	}, []);

	const formatedCountry = useMemo(
		() =>
			countryList?.map((country: { name: string }) => ({
				label: formatValue(country.name ?? '--'),
				value: country.name,
			})),
		[countryList]
	);

	useEffect(() => {
		if (!countryList?.length) {
			fetchCountryData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [countryList]);

	useEffect(() => {
		if (selectedCountry?.value && selectedState?.value) {
			handleUpdateCountryDetails({
				country: selectedCountry?.value,
				state: selectedState?.value,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedCountry, selectedState]);

	return !isLoadingContries ? (
		<div className="CounteryStateDropdown__container">
			<ReactDropdown
				options={formatedCountry}
				isSearchable={true}
				handleChangeSelect={handleOnChange}
				optionsDropHeight={230}
				label="Country"
				value={selectedCountry}
				placeholder="Country"
				key="Country"
			/>
		</div>
	) : (
		<Loader dimension={30} type="loader-blue" />
	);
};
