import { Fragment, useEffect, useState } from 'react';
import { Loader } from '@storybook'; // Storybook Loader component

import { PRIVACY_POLICY } from 'constants/common'; // Configuration for the privacy policy

import './privacy-policy.scss'; // Custom styles for the privacy policy

export const PrivacyPolicy = () => {
	// State to track the loading status
	const [isLoading, setIsLoading] = useState(true);

	// Destructure tokenId and url from the PRIVACY_POLICY object
	const { tokenId, url } = PRIVACY_POLICY ?? {};

	useEffect(() => {
		// Access the global OneTrust object
		const OneTrust = (window as any).OneTrust;

		// Initialize the OneTrust notice if available
		OneTrust?.NoticeApi?.Initialized?.then(() => {
			OneTrust.NoticeApi.LoadNotices([url]);
		});

		// Simulate a delay before marking the loading as complete
		const timeoutId = setTimeout(() => setIsLoading(false), 500);

		// Cleanup the timeout on unmount
		return () => clearTimeout(timeoutId);

		// Dependencies include only the URL since isLoading is managed locally
	}, [url]);

	return (
		<Fragment>
			{/* Show the loader while loading */}
			{isLoading && (
				<div className="loader-wrapper">
					<Loader className="loader-blue" dimension={40} />
				</div>
			)}

			{/* Render the privacy policy notice once loading is complete */}
			<div
				style={{ display: isLoading ? 'none' : 'block' }}
				id={tokenId}
				className="otnotice"
			></div>
		</Fragment>
	);
};
