import {
	ChangeEvent,
	Fragment,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import classNames from 'classnames';
import {
	CountryMobileNumber,
	CustomDatePicker,
	Input,
	Loader,
	ReactSwitch,
} from '@storybook';
import {
	BusinessCompanyMemberListError,
	COMPANY_NONE_MEMBER_FORM,
	EXIST_KYC_AML_MEMBERS_FORM,
	customMember,
	defaultCustomMember,
} from 'views/kyb/constants';
import {
	ICompanyMemberList,
	SearchKybCompanyMembersInputState,
	SearchKybCompanyMembersListState,
	TheKYBCompanySelectedMemberErrorState,
	TheKYBCompanyMemberForKycAmlVerification,
	TheKYBCompanyMemberForKycAmlVerificationListState,
	TheKybMemberListLoader,
} from 'views/kyb/stores';
import {
	isValidORDateGreaterThanToday,
	validateDOB,
	validateEmail,
} from 'utils';

export const SelectKycAmlCompanyMember = () => {
	// global states
	const [companyMemberList, setCompanyMemberList] = useRecoilState(
		TheKYBCompanyMemberForKycAmlVerificationListState
	);
	const [selectedMembers, setSelectedMembers] = useRecoilState(
		TheKYBCompanyMemberForKycAmlVerification
	);
	const memberListLoading = useRecoilValue(TheKybMemberListLoader);

	// this states is only for search member input
	const searchKybMembersList = useRecoilValue(SearchKybCompanyMembersListState);
	const searchKybMembersInputValue = useRecoilValue(
		SearchKybCompanyMembersInputState
	);
	const [kybCompanyMemberErrorIds, setKybCompanyMemberErrorIds] =
		useRecoilState(TheKYBCompanySelectedMemberErrorState);

	// local states
	const [error, setError] = useState<{
		[key: string]: { [id: number | string]: string };
	}>({
		date_of_birth: {},
		email: {},
		national_id_number: {},
		phone: {},
	});

	// hooks
	const firstCardRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		// this is for scroll to the top at the component called
		if (firstCardRef.current) {
			firstCardRef.current.scrollIntoView({
				behavior: 'instant',
				block: 'start',
			});
		}
	}, []);

	useEffect(() => {
		const isEmpty = Object.values(error).every(value =>
			Object.values(value).every(val => val === '')
		);
		setSelectedMembers(prevState => ({
			...prevState,
			isError: isEmpty,
		}));
	}, [error, setSelectedMembers]);

	const getInitials = useCallback(
		(name: string, index: number) => {
			if (name === defaultCustomMember.id) {
				const isCustomMemberActive = companyMemberList?.activeIndex?.[index]
					? 'ri-indeterminate-circle-fill'
					: ' ri-add-circle-fill';

				return (
					<i
						className={`kyb-members__card__kyc-aml-wrapper__add-icon ${isCustomMemberActive}`}
					/>
				);
			}
			const words = name?.split?.(' ') ?? [];
			const initials = words.map(word => word.charAt(0)).join('');
			return initials.substring(0, 2);
		},
		[companyMemberList?.activeIndex]
	);

	const handleInputChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>, id: string) => {
			setKybCompanyMemberErrorIds({});
			const { name, value } = e?.target ?? {};
			setCompanyMemberList((prev: any) => {
				// Find the index of the member with the given id
				const index = prev.members.findIndex(
					(member: ICompanyMemberList) => member.id === id
				);

				// If the member is not found, return the previous state
				if (index === -1) return prev;

				if (name === 'date_of_birth' && isValidORDateGreaterThanToday(value)) {
					setError(prev => ({
						...prev,
						[name]: { ...prev[name], [id]: 'Invalid date' },
					}));
					return {
						...prev,
						members: prev.members.map(
							(member: ICompanyMemberList, idx: number) =>
								idx === index ? { ...member, [name]: '' } : member
						),
					};
					return;
				}

				if (name === 'date_of_birth' && !validateDOB(value)) {
					setError(prev => ({
						...prev,
						[name]: {
							...prev[name],
							[id]: 'You must be at least 18 years old to access this content.',
						},
					}));
					return {
						...prev,
						members: prev.members.map(
							(member: ICompanyMemberList, idx: number) =>
								idx === index ? { ...member, [name]: '' } : member
						),
					};
				}

				setError(prev => ({
					...prev,
					[name]: { ...prev[name], [id]: '' },
				}));

				if (name === 'national_id_number') {
					let number = value;
					if (/[^-0-9]/g.test(number)) return prev;
					number = number?.replace(/[^0-9a-zA-Z]/g, '');
					if (number.length > 15) {
						// Show error if the number exceeds 15 digits
						setError(prev => ({
							...prev,
							[name]: {
								...prev[name],
								[id]: BusinessCompanyMemberListError.SSN_FIFTEEN_DIGIT,
							},
						}));
						return prev;
					}
					number = number?.substring(0, 15);
					number = number?.replace(/(\w{3})(\w{2})(\w{4})/, '$1-$2-$3');
					if (number.length < 11) {
						setError(prev => ({
							...prev,
							[name]: { ...prev[name], [id]: '' },
						}));
					} else {
						setError(prev => ({
							...prev,
							[name]: { ...prev[name], [id]: '' },
						}));
					}

					return {
						...prev,
						members: prev.members.map(
							(member: ICompanyMemberList, idx: number) =>
								idx === index
									? { ...member, [name]: number.replaceAll('-', '') }
									: member
						),
					};
				}

				return {
					...prev,
					members: prev.members.map(
						(member: ICompanyMemberList, idx: number) =>
							idx === index ? { ...member, [name]: value } : member
					),
				};
			});

			if (name === 'email') {
				if (validateEmail(value)) {
					setError(prev => ({
						...prev,
						[name]: { ...prev[name], [id]: 'Invalid email Id' },
					}));
				} else {
					setError(prev => ({
						...prev,
						[name]: { ...prev[name], [id]: '' },
						date_of_birth: { ...prev.date_of_birth, [id]: '' },
						national_id_number: { ...prev.national_id_number, [id]: '' },
					}));
				}
			}
		},
		[setCompanyMemberList, setKybCompanyMemberErrorIds]
	);

	const getValueInput = useCallback(
		(key: string, id: string) => {
			const data = companyMemberList?.members;
			const item = data.find(item => item.id === id);

			if (item) {
				if (key === 'national_id_number') {
					return (
						(item[key] as string)?.replace(
							/(\w{3})(\w{2})(\w{4})/,
							'$1-$2-$3'
						) ?? ''
					);
				} else {
					return (item[key] as string) ?? '';
				}
			}
			// Return an empty string if item with the specified id is not found
			return '';
		},
		[companyMemberList]
	);

	const getDateValue = useCallback(
		(index: number) => {
			const date = companyMemberList.members?.[index]?.['date_of_birth'] ?? {};
			if (date && typeof date === 'string') {
				const updateDate = new Date(date);
				const paylaod = {
					day: updateDate.getDate(),
					month: updateDate.getMonth() + 1,
					year: updateDate.getFullYear(),
				};
				return paylaod;
			}
			if (Object.keys(date).length > 0) {
				return date;
			}
			return null;
		},
		[companyMemberList.members]
	);

	const onHandlePhoneChange = useCallback(
		(countryCode: string | number, phone: string, id: string) => {
			setKybCompanyMemberErrorIds({});
			if ((/^\d+$/.test(phone) || phone === '') && phone.length <= 12) {
				setCompanyMemberList(prev => {
					const pre = JSON.parse(JSON.stringify(prev));
					// Find the index of the item with the given id
					const index = pre.members.findIndex(
						(member: ICompanyMemberList) => member.id === id
					);

					if (index !== -1) {
						pre.members[index] = {
							...pre.members[index],
							phone,
							country_code: countryCode,
						};
					}
					return { ...pre };
				});

				if (phone.length < 8) {
					setError(prev => ({
						...prev,
						['phone']: {
							...prev['phone'],
							[id]: 'Phone should be at least 8 digits',
						},
					}));
				} else {
					setError(prev => ({
						...prev,
						['phone']: { ...prev['phone'], [id]: '' },
					}));
				}
			}
		},
		[setCompanyMemberList, setKybCompanyMemberErrorIds]
	);

	const onHandleChangeCountry = useCallback(
		(countryCode: string | number, index: number) => {
			if (searchKybMembersInputValue.length >= 1) {
				setCompanyMemberList(prev => {
					const pre = JSON.parse(JSON.stringify(prev));

					const activeKeys = Object.keys(pre.activeIndex);

					const activeTrueKeys = activeKeys.filter(key => pre.activeIndex[key]);
					if (index < activeTrueKeys.length) {
						const activeKey = activeTrueKeys[index];
						const memberIndex = pre.members.findIndex(
							(member: any) => member.id === activeKey
						);
						if (memberIndex !== -1) {
							pre.members[memberIndex] = {
								...pre.members[memberIndex],
								country_code: countryCode,
							};
						} else {
							// eslint-disable-next-line no-console
							console.warn(`No member found with id: ${activeKey}`);
						}
					} else {
						// eslint-disable-next-line no-console
						console.warn(`No active key found at index: ${index}`);
					}

					return { ...pre };
				});
			} else {
				setCompanyMemberList(prev => {
					const pre = JSON.parse(JSON.stringify(prev));
					pre.members[index] = {
						...pre.members[index],
						country_code: countryCode,
					};
					return { ...pre };
				});
			}
		},
		[setCompanyMemberList, searchKybMembersInputValue]
	);

	const selectedMemberForm = useCallback(
		(form: any, index: number, id: string) => {
			return form.map((el: any) => {
				switch (el.type) {
					case 'text':
						return (
							<Fragment key={`${el.name}_${index.toString()}`}>
								<Input
									label={el.label}
									inputType={el.type}
									placeholder={el.placeHolder}
									handleChange={e => handleInputChange(e, id)}
									inputName={el.name}
									value={getValueInput(el.name, id)}
									isRequired
									errorMessage={error[el.name]?.[id]?.toString()}
									isError={!!error?.[el.name]?.[id]?.toString()?.length}
								/>
							</Fragment>
						);

					case 'phone':
						return (
							<CountryMobileNumber
								label={el.label}
								handleChange={e =>
									onHandlePhoneChange(e.countryCode, e.phone, id)
								}
								defaultCountryCode={getValueInput('country_code', id) || '+1'}
								defaultNumber={getValueInput('phone', id)}
								handleChangeCountry={e => onHandleChangeCountry(e, index)}
								isRequired
								errorMessage={error[el.name]?.[id]?.toString()}
								isError={!!error?.[el.name]?.[id]?.toString()?.length}
							/>
						);

					case 'date':
						return (
							<CustomDatePicker
								value={getDateValue(index)}
								label={el.label}
								onChange={(e: any) => handleInputChange(e, id)}
								name="date_of_birth"
								errorMessage={error['date_of_birth']?.[id]?.toString()}
								isError={!!error?.[el.name]?.[id]?.toString()?.length}
								isRequired
							/>
						);

					default:
						return <></>;
				}
			});
		},
		[
			error,
			getDateValue,
			getValueInput,
			handleInputChange,
			onHandlePhoneChange,
			onHandleChangeCountry,
		]
	);

	const kybMemberClass = useCallback(
		(id: string) => {
			const isActive = companyMemberList.activeIndex?.[id] ?? false;
			// paras: this error only for kyc-aml member list
			const isError = kybCompanyMemberErrorIds[id] ?? false;
			return classNames('kyb-members__card', {
				'kyb-members__card_active': isActive,
				'kyb-members__card_error': isError,
			});
		},
		[companyMemberList.activeIndex, kybCompanyMemberErrorIds]
	);

	const handleToggle = useCallback(
		(id: string, isToggle?: boolean) => {
			setCompanyMemberList(prevState => {
				let updatedMembers = prevState.members;
				if (id === 'custom') {
					// If 'custom' is added, filter out existing 'custom' and add new customMember
					updatedMembers = prevState.members.filter(
						member => member.id !== 'custom'
					);
					// Adding 'customMember' to 'updatedMembers' array with 'any' typecasting.
					// Using 'any' because 'customMember' can be of multiple types.
					updatedMembers.push(customMember as any);
				}

				return {
					...prevState,
					members: updatedMembers,
					activeIndex: {
						...prevState.activeIndex,
						[id]: id !== 'custom' ? !prevState.activeIndex?.[id] : true,
					},
				};
			});

			setSelectedMembers(prevSelected => {
				// Find the index of the id
				const index = prevSelected.id.indexOf(id);

				const updatedIndex =
					index !== -1
						? prevSelected.index.filter(
								item => item !== prevSelected.index[index]
							)
						: [...prevSelected.index];

				const updatedId =
					index !== -1
						? prevSelected.id.filter(item => item !== id)
						: [...prevSelected.id, id];

				return {
					...prevSelected,
					index: updatedIndex,
					id: updatedId,
				};
			});

			if (isToggle) {
				setError({
					email: {},
					date_of_birth: {},
					national_id_number: {},
					phone: {},
				});
			}
		},
		[setCompanyMemberList, setSelectedMembers]
	);

	const handleOpenForm = useCallback(
		(id: string) => {
			if (id !== 'custom') {
				handleToggle(id);
				return;
			}

			setCompanyMemberList(prevState => {
				let updatedMembers = prevState.members;
				if (id === 'custom') {
					// If 'custom' is added, filter out existing 'custom' and add new customMember
					updatedMembers = prevState.members.filter(
						member => member.id !== 'custom'
					);
					updatedMembers.push(customMember as any);
				}

				return {
					...prevState,
					members: updatedMembers,
					activeIndex: {
						...prevState.activeIndex,
						[id]: !prevState.activeIndex?.[id],
					},
				};
			});

			setError({
				email: {},
				date_of_birth: {},
				national_id_number: {},
				phone: {},
			});
		},
		[handleToggle, setCompanyMemberList]
	);

	const renderDoKycAmlToggle = useCallback(
		(id: string) => {
			return (
				<div className="kyc-aml-members__toggle-wrapper">
					<div className="kyc-aml-members__toggle-wrapper__title-container">
						<span className="kyc-aml-members__toggle-wrapper__title-container__title">
							KYC & AML verification
						</span>
						<span className="kyc-aml-members__toggle-wrapper__title-container__description">
							Enable this option to perform KYC & AML verification for this
							representative.
						</span>
					</div>
					<ReactSwitch
						checked={selectedMembers.id.includes(id)}
						handleChange={() => handleToggle(id, true)}
						id={'representative-do-kyc-aml-toggle'}
					/>
				</div>
			);
		},
		[handleToggle, selectedMembers.id]
	);

	const renderCard = useCallback(
		(name: string, designation: string, index: number, id: string) => {
			return (
				<>
					<div className="kyb-members__card__kyc-aml-wrapper">
						<div onClick={() => handleOpenForm(id)}>
							<div className="kyb-members__card__kyc-aml-wrapper__card-header">
								<div className="kyb-members__card__para">
									{getInitials(name, index)}
								</div>
								<div className="kyb-members__card__initial">
									<div className="kyb-members__card__kyc-aml-wrapper__text">
										{name === defaultCustomMember.id
											? defaultCustomMember.name
											: name}
									</div>
									<div className="kyb-members__card__initial__designation">
										{name === defaultCustomMember.id
											? defaultCustomMember.designation
											: designation}
									</div>
								</div>
							</div>
							<input
								type="checkbox"
								name="selectMemberCard"
								checked={selectedMembers.index.includes(index)}
								className="kyb-list__radio-button"
								onChange={() => handleToggle(id)}
								hidden
							/>
						</div>
						{renderDoKycAmlToggle(id)}
					</div>
				</>
			);
		},
		[
			getInitials,
			selectedMembers.index,
			renderDoKycAmlToggle,
			handleOpenForm,
			handleToggle,
		]
	);

	const kybCompanyMemberList = useMemo(() => {
		return searchKybMembersInputValue.length >= 1
			? searchKybMembersList
			: companyMemberList;
	}, [
		companyMemberList,
		searchKybMembersInputValue.length,
		searchKybMembersList,
	]);

	const memeberListLoader = useMemo(
		() =>
			!memberListLoading ? (
				<div className="kyb-list__loader-wrapper">
					<div className="kyb-list__loader-box">
						<Loader type="loader" dimension={20} />{' '}
					</div>
					<div>Please wait fetching more members.</div>
				</div>
			) : null,
		[memberListLoading]
	);

	return (
		<>
			<div className="kyb-members">
				{kybCompanyMemberList.members.map((member, index) => {
					const { name, firstName, lastName, designation, id } = member;
					const fullName =
						`${firstName || ''} ${lastName || ''}`.trim() || name;
					return (
						<div
							key={id}
							className={kybMemberClass(id)}
							ref={index === 0 ? firstCardRef : null}
						>
							{renderCard(fullName, designation, index, id)}

							{companyMemberList.activeIndex?.[id] && (
								<div className="kyb-members__card__input-wrapper">
									{selectedMemberForm(
										kybCompanyMemberList.members.length - 1 === index
											? COMPANY_NONE_MEMBER_FORM
											: EXIST_KYC_AML_MEMBERS_FORM,
										index,
										id
									)}
								</div>
							)}
						</div>
					);
				})}
				{memeberListLoader}
			</div>
		</>
	);
};
