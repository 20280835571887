import { QRCodeSVG } from 'qrcode.react';
import { SkeltonLoader } from '@storybook';

/**
 * Interface for defining settings for an embedded image in the QR code.
 */
interface IImageSettings {
	/**
	 * The URI of the embedded image.
	 */
	src: string;
	/**
	 * The height, in pixels, of the image.
	 */
	height: number;
	/**
	 * The width, in pixels, of the image.
	 */
	width: number;
	/**
	 * Whether to excavate the modules around the embedded image.
	 * Excavation ensures overlapping modules use the background color.
	 */
	excavate: boolean;
}

/**
 * Props for the QrCodeGenerator component.
 */
export interface QrCodeGeneratorProps {
	/**
	 * The content to encode in the QR code.
	 */
	value: string;
	/**
	 * The size of the QR code in pixels (default: 200).
	 */
	size?: number;
	/**
	 * The error correction level (default: 'H').
	 * Options: 'L', 'M', 'Q', 'H' (Low, Medium, Quartile, High).
	 */
	level?: 'L' | 'M' | 'Q' | 'H';
	/**
	 * Settings for embedding an image within the QR code.
	 */
	imageSettings?: IImageSettings;
	/**
	 * Indicates whether the QR code loading process is in progress.
	 * Set to true when the QR code is being processed, and false when it is ready or finished.
	 */
	qrLoading?: boolean;
}

/**
 * A reusable QR code generator component using the `qrcode.react` library.
 * Supports optional size, error correction level, and embedded images.
 */
export const QrCodeGenerator = ({
	value,
	size = 200,
	level = 'H',
	imageSettings,
	qrLoading = false,
}: QrCodeGeneratorProps) => {
	// Construct the props for the QRCodeSVG component
	const qrProps = {
		value,
		size,
		level,
		imageSettings,
	};

	// If the QR code is still loading (qrLoading is true), display a skeleton loader
	// with a width and height of 200px to indicate loading progress to the user.
	if (qrLoading) {
		return <SkeltonLoader width={`${size}px`} height={`${size}px`} />;
	}

	// Render the QR code with the given props
	return <QRCodeSVG {...qrProps} />;
};
