import { FlowType, MAX_RETRIES, RETRY_DELAY } from 'constants/common';

import { Button, Loader } from '@storybook';
import { useCallback, useEffect, useMemo, useState } from 'react';
import AppleLogin from 'react-apple-login';
import { useSetRecoilState, useRecoilValue } from 'recoil';

import { BodyWrapper, LabelElement } from 'components';
import {
	useNetwork,
	useNextStep,
	useSharedVariables,
	useUrl,
	useWebAuthentication,
	useTokenSession,
} from 'hooks';
import { getiOSVersion, isMacOrIOS, isMobileDevice } from 'utils';

import { APIS, API_TYPE } from 'constants/api';
import {
	AppleResponseToken,
	AppleUserDetailsState,
} from 'views/basic-information/store';
import { PREVENT_WEBAUTHN_FOR_URLS } from 'views/constants';
import { PREVENTAPPLE_LOGIN_FOR_URLS, isSafariBroswerDetect } from './constant';
import { BiometricPolicy, PrivacyPolicy, TermAndCondition } from './pages';

import {
	AccessTokenState,
	LoginPhoneNumberState,
	WebComponentMetaDataState,
} from 'states';
import { PrerequisiteWebgl } from 'views/prerequisite-webgl';

import './terms-condition.scss';
import { useGetLoationDetails } from 'hooks/location-details';
import { useConnectionSpeedCheck } from 'views/internet-connection-speed/hooks/useConnectionSpeedCheck';
import { retryOperation } from 'utils/retry-api-call';

export const TermConditions = () => {
	const [selectedStep, setSelectedStep] = useState('term-and-condition');
	const [isChecked, setIsChecked] = useState(false);
	const [isPoliciesChecked, setIsPoliciesChecked] = useState(false);
	const [isBioMetricChecked, setIsBioMetricChecked] = useState(false);
	const [allowToggle, setAllowToggle] = useState<boolean>(true);
	const [isLoading, setIsLoading] = useState(false);
	const { code: sessionCode } = useRecoilValue(AccessTokenState);
	const webComponentMetaData = useRecoilValue(WebComponentMetaDataState);
	const { getGeoInfo } = useGetLoationDetails();
	const { handleNext, sessionPayloadDetail, setSessionDetails } = useNextStep();
	const { authenticate, registerNewCredentials, getRegistrations } =
		useWebAuthentication();

	// Awadhesh: Apple id details
	const { post: postDeviceToken } = useNetwork();
	const setAppleUserDetails = useSetRecoilState(AppleUserDetailsState);
	const setLoginPhoneNumber = useSetRecoilState(LoginPhoneNumberState);
	const setAppleResponseToken = useSetRecoilState(AppleResponseToken);

	const { code } = useUrl();
	const { postTokenSession } = useTokenSession();
	const { onboardingType, hideAppleId } = useSharedVariables();
	const checkInternetSpeed = useConnectionSpeedCheck();

	const iosVersion = getiOSVersion();

	const { isWebComponent, termsCondition = true } = webComponentMetaData ?? {};

	const { email, phone, name, userId, sessionId, _id } = useMemo(
		() => sessionPayloadDetail ?? {},
		[sessionPayloadDetail]
	);
	const renderLabel = useMemo(() => {
		switch (selectedStep) {
			case 'term-and-condition':
				return 'Terms and Conditions';
			case 'privacy-policy':
				return 'Privacy Policy';
			case 'biometric-policy':
				return 'Biometric Policy';
		}
		return '';
	}, [selectedStep]);

	useEffect(() => {
		checkInternetSpeed();
	}, [checkInternetSpeed]);

	const handleOnSuccess = useCallback(async () => {
		const resp = await getGeoInfo();
		const { ip } = resp ?? {};

		if (onboardingType === 'complex') {
			// COMPLEX_SESSION patch api
			const resp = await postTokenSession({
				payload: {
					sessionId: _id,
					userId,
					userAgent: true,
					stepId: 'term-condition',
					ipAddress: ip || undefined,
				},
				code: sessionCode,
			});

			if (resp?.statusCode === 200) {
				setSessionDetails(prev => ({ ...prev, steps: [] }));
			}
			return;
		}
		if (sessionId && userId) {
			await postTokenSession({
				payload: {
					sessionId,
					userId,
					userAgent: true,
				},
			});
		}
		handleNext();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sessionId, userId, handleNext, _id, sessionCode]);

	useEffect(() => {
		if (isWebComponent && !termsCondition) {
			if (sessionId && userId) {
				handleOnSuccess();
			} else {
				handleNext();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [termsCondition]);

	const handleOnFailed = useCallback(() => null, []);

	const isDisabled = useMemo(() => {
		if (isChecked && isPoliciesChecked && isBioMetricChecked) return false;
		return true;
	}, [isChecked, isPoliciesChecked, isBioMetricChecked]);

	const handleOnClickProceed = useCallback(async () => {
		setIsLoading(true);
		setAllowToggle(false);

		if (
			PREVENT_WEBAUTHN_FOR_URLS.includes(document.referrer) ||
			onboardingType === 'mobile' ||
			window.self !== window.top
		) {
			await handleOnSuccess();
			setIsLoading(false);
			return;
		}
		const isAvailable =
			await PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable();

		if (isAvailable) {
			const registerPayload = {
				email: phone ?? email ?? 'it@satschel.com',
				id: userId ?? 'it@satschel.com',
				displayName: name ?? 'Satschel',
			};

			const isAlreadyExist = getRegistrations().find(
				(item: any) => item.name === email || item.name === phone
			);

			if (!isAlreadyExist) {
				registerNewCredentials(handleOnSuccess, registerPayload);
			} else {
				authenticate(handleOnSuccess, handleOnFailed);
			}
		} else handleOnSuccess();
	}, [
		onboardingType,
		handleOnSuccess,
		phone,
		email,
		userId,
		name,
		getRegistrations,
		registerNewCredentials,
		authenticate,
		handleOnFailed,
	]);

	const handleOnChange = useCallback(
		(
			e: React.MouseEvent<HTMLElement> | React.ChangeEvent<HTMLInputElement>
		) => {
			e.stopPropagation();
			setIsChecked(prev => !prev);
		},
		[]
	);

	const handleCheckPolicies = useCallback(
		(
			e: React.MouseEvent<HTMLElement> | React.ChangeEvent<HTMLInputElement>
		) => {
			e.stopPropagation();
			setIsPoliciesChecked(prev => !prev);
		},
		[]
	);

	const handleBioMetricCheckbox = useCallback(
		(
			e: React.MouseEvent<HTMLElement> | React.ChangeEvent<HTMLInputElement>
		) => {
			e.stopPropagation();
			setIsBioMetricChecked(prev => !prev);
		},
		[]
	);

	const deviceAppleToken = useCallback(
		async (appleRes: any) => {
			const requestUrl = `${APIS.TOKEN}?${
				appleRes?.authorization?.id_token
					? `tokenId=${appleRes.authorization.id_token}`
					: ''
			}${
				appleRes?.user?.name?.firstName
					? `&firstName=${appleRes.user.name.firstName}`
					: ''
			}${
				appleRes?.user?.name?.lastName
					? `&lastName=${appleRes.user.name.lastName}`
					: ''
			}${
				appleRes?.user?.name?.email ? `&email=${appleRes.user.name.email}` : ''
			}${`&qrId=${code}`}`;

			if (appleRes?.authorization?.id_token) {
				const response = await retryOperation(
					() =>
						postDeviceToken(requestUrl, {
							code,
							type: 'get-device-tokens',
							payload: {},
						}),
					MAX_RETRIES,
					RETRY_DELAY
				);
				if (response?.statusCode === 200) {
					const data = response['0'] ?? {};
					setAppleUserDetails(data);
					setLoginPhoneNumber({
						phone: data?.phone ?? '',
						countryCode: data?.countryCode ?? '+1',
					});
				}
			}
		},
		[code, postDeviceToken, setAppleUserDetails, setLoginPhoneNumber]
	);

	const handleOnClickAppleLogin = useCallback(
		async (response: any) => {
			const payload = {
				code: sessionCode,
				payload: {
					userId: userId,
					token: { response },
					type:
						onboardingType === FlowType.Complex
							? FlowType.Complex
							: FlowType.Linear,
				},
				type: 'device-tokens',
			};
			setAppleResponseToken(response);
			if (!response.error) {
				if (onboardingType !== FlowType.QR) {
					await postTokenSession({
						payload: payload.payload,
						type: API_TYPE.DEVICE_TOKEN,
						code: sessionCode,
					});
				} else {
					deviceAppleToken(response);
				}
				handleOnSuccess();
			}
			setIsLoading(false);
		},
		[
			sessionCode,
			userId,
			onboardingType,
			setAppleResponseToken,
			handleOnSuccess,
			postTokenSession,
			deviceAppleToken,
		]
	);

	const renderButtonLabel = useMemo(() => {
		if (isLoading) {
			return <Loader type="loader" dimension={20} className="loader-white" />;
		}
		return 'Proceed';
	}, [isLoading]);

	const getButton = useMemo(() => {
		if (isMacOrIOS() && !hideAppleId) {
			const redirectURI = window.location.origin;
			const isMobile = isMobileDevice();
			let render = (
				<Button
					label={renderButtonLabel}
					handleClick={handleOnClickProceed}
					type="button__filled button__filled--primary button__large button__block mt-2"
					disabled={isDisabled}
				/>
			);
			if (
				(isMobile || isSafariBroswerDetect) &&
				!PREVENTAPPLE_LOGIN_FOR_URLS.includes(window.location.host)
			) {
				render = (
					<AppleLogin
						// clientId="com.satschel.stage.secure"
						clientId="com.simplici.secure"
						redirectURI={redirectURI}
						usePopup={true}
						callback={handleOnClickAppleLogin} // Catch the response
						scope="email name"
						responseMode="query"
						render={(
							renderProps //Custom Apple Sign in Button
						) => (
							<Button
								label={renderButtonLabel}
								handleClick={() => {
									setIsLoading(true);
									renderProps.onClick();
								}}
								type="button__filled button__filled--primary button__large button__block mt-2 process-button__size"
								disabled={isDisabled}
							/>
						)}
					/>
				);
			}
			return render;
		} else
			return (
				<Button
					label="Proceed"
					handleClick={handleOnSuccess}
					type="button__filled button__filled--primary button__large button__block mt-2"
					disabled={isDisabled}
				/>
			);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isPoliciesChecked, isDisabled, renderButtonLabel]);

	const handlePage = useCallback(
		(page: string, e: React.MouseEvent<HTMLElement>) => {
			e.stopPropagation();
			setSelectedStep(page);
			window.scrollTo({
				top: document.documentElement.scrollHeight,
				behavior: 'smooth',
			});
		},
		[]
	);

	const renderContent = useMemo(() => {
		switch (selectedStep) {
			case 'term-and-condition':
				return <TermAndCondition />;
			case 'privacy-policy':
				return <PrivacyPolicy />;
			case 'biometric-policy':
				return <BiometricPolicy />;
		}
		return <></>;
	}, [selectedStep]);

	const bodyElement = useMemo(
		() => (
			<div className="tc_wrapper">
				<div className="tc_wrapper__body">{renderContent}</div>
				<div className="tc_wrapper__footer">
					<div className="tc_wrapper__footer__action">
						<input
							type="checkbox"
							id="tc-checkbox"
							hidden
							checked={isChecked}
							disabled={!allowToggle}
							onChange={handleOnChange}
						/>
						<label htmlFor="tc-checkbox" />
						<div
							className="tc_wrapper__footer__action__content"
							onClick={handleOnChange}
						>
							I agree to{' '}
							<span
								onClick={e => handlePage('term-and-condition', e)}
								className="privacy"
							>
								Terms and Conditions
							</span>
						</div>
					</div>
					<div className="tc_wrapper__footer__action">
						<input
							type="checkbox"
							id="policies-checkbox"
							hidden
							checked={isPoliciesChecked}
							disabled={!allowToggle}
							onChange={handleCheckPolicies}
						/>
						<label htmlFor="policies-checkbox" />
						<div
							className="tc_wrapper__footer__action__content"
							onClick={handleCheckPolicies}
						>
							I agree to{' '}
							<span
								onClick={e => handlePage('privacy-policy', e)}
								className="privacy"
							>
								Privacy Policy
							</span>
						</div>
					</div>
					<div className="tc_wrapper__footer__action">
						<input
							type="checkbox"
							id="biometric-checkbox"
							hidden
							checked={isBioMetricChecked}
							disabled={!allowToggle}
							onChange={handleBioMetricCheckbox}
						/>
						<label htmlFor="biometric-checkbox" />
						<div
							className="tc_wrapper__footer__action__content"
							onClick={handleBioMetricCheckbox}
						>
							I consent to the collection, use, sharing and retention of my
							biometric data as described in the{' '}
							<span
								onClick={e => handlePage('biometric-policy', e)}
								className="privacy"
							>
								Biometric Policy
							</span>
						</div>
					</div>
					{getButton}
				</div>
			</div>
		),

		[
			allowToggle,
			getButton,
			handleBioMetricCheckbox,
			handleCheckPolicies,
			handleOnChange,
			handlePage,
			renderContent,
			isBioMetricChecked,
			isChecked,
			isPoliciesChecked,
		]
	);

	if (iosVersion !== null && iosVersion <= 15) {
		return <PrerequisiteWebgl />;
	}

	return (
		<div className="terms_header_container">
			{isWebComponent && !termsCondition ? (
				<div className="loading-steps">
					<Loader />
				</div>
			) : (
				<BodyWrapper
					optionalClassName="terms-body-wrapper"
					label={
						<div className="terms_header">
							<LabelElement text={renderLabel} />
						</div>
					}
					bodyElement={bodyElement}
				/>
			)}
		</div>
	);
};
