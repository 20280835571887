import { ChangeEvent, useCallback, useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Input, IOption, ReactDropdown } from '@storybook';

import {
	BUSINESS_INFO_CONSTATNTS,
	KYB_BUSINESS_VERIFICATION,
	THE_KYB_PROVIDER_TOKEN,
} from 'views/kyb/constants';
import {
	BusinessInformationState,
	CountryStateList,
	CountryStateListState,
	CustomOptionDropdownProps,
	IBuisness_information,
	TheKYBCountryListState,
	TheKYBStateListState,
} from 'views/kyb/stores';
import useFetchWithToken from 'hooks/use-fetch-with-token/use-fetch-with-token';
import { formatValue } from 'utils';
import { APIS } from 'constants/api';
import { useSharedVariables } from 'hooks';
import { OnlyCharNumebrRegex } from 'constants/common';
import { userBuisnessIdState } from 'hooks/use-next-step/stores';
import { EVENTS_TRACKING, timestamp, useTrackEvents } from 'helpers';

const maxLength = 15;

export const BusinessVerification = () => {
	// hooks
	const { envHost } = useSharedVariables();
	const { fetchData } = useFetchWithToken();
	const { trackEvents } = useTrackEvents();
	// Recoils
	const [formattedCountries, setFormattedCountries] = useRecoilState(
		TheKYBCountryListState
	);
	const userBuisnessId = useRecoilValue(userBuisnessIdState);

	const [countryListResp, setCountryListResp] = useRecoilState(
		CountryStateListState
	);
	const [businessInformation, setBusinessInformation] = useRecoilState<
		IBuisness_information | any
	>(BusinessInformationState);
	// local states
	const [statesList, setStateList] = useRecoilState(TheKYBStateListState);

	const { feinPlaceholder, KybCountryRegistration, isUS } = useMemo(() => {
		const isUS =
			businessInformation?.country === BUSINESS_INFO_CONSTATNTS.IsUSCountry;
		return {
			feinPlaceholder: isUS
				? BUSINESS_INFO_CONSTATNTS.EinPlaceHolder
				: BUSINESS_INFO_CONSTATNTS.RegistrationPlaceHolder,
			KybCountryRegistration: isUS
				? BUSINESS_INFO_CONSTATNTS.EinLabel
				: BUSINESS_INFO_CONSTATNTS.RegistrationLabel,
			isUS,
		};
	}, [businessInformation.country]);

	const setFormatedCountryData = useCallback((data: any) => {
		const countryList = data
			?.filter((country: CountryStateList) => country?.kyb?.country)
			?.map((country: { name: string }) => ({
				label: formatValue(country.name ?? '--'),
				value: country.name,
			}));
		setFormattedCountries(countryList);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const fetchDataFromAPI = async () => {
			try {
				const response = await fetchData(
					APIS.COUNTRY_LIST,
					THE_KYB_PROVIDER_TOKEN[envHost as keyof typeof THE_KYB_PROVIDER_TOKEN]
				);

				if (response?.message === 'ok') {
					const { data } = response;
					setCountryListResp(data);
					setFormatedCountryData(data);
				}

				// Do something with the response
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error('Error fetching data:', error);
			}
		};
		if (countryListResp.length === 0) {
			fetchDataFromAPI();
		} else {
			setFormatedCountryData(countryListResp);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [countryListResp]);

	const validateFein = useCallback(
		(value: string, name: string) => {
			let errorMessage = '';
			if (isUS) {
				const digits = value?.replace(/[^0-9a-zA-Z]/g, '');
				if (!OnlyCharNumebrRegex.test(value.trim())) {
					errorMessage = `Invalid ${KybCountryRegistration}`;
				} else if (digits.length > maxLength) {
					errorMessage = `The maximum length for ${KybCountryRegistration} must be 15 digits`;
				}
			} else {
				const NonUSFeinRegex = /^[a-zA-Z0-9\-/]+$/;
				if (!NonUSFeinRegex.test(value.trim())) {
					errorMessage = `Only alphanumeric characters '-' and '/' are allowed in registration number.`;
				}
			}
			setBusinessInformation((prev: IBuisness_information) => ({
				...prev,
				error: {
					[`error_${name}`]: errorMessage,
				},
			}));
		},
		[KybCountryRegistration, setBusinessInformation, isUS]
	);

	useEffect(() => {
		if (businessInformation?.fein) {
			validateFein(businessInformation?.fein, 'fein');
		}
	}, [
		businessInformation.country,
		businessInformation.fein,
		validateFein,
		isUS,
	]);

	const handleChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>, key?: string) => {
			const { name, value } = e.target;
			const feinValue = value
				.replace(/[^0-9a-zA-Z/\- ]/g, '')
				.replace(/\s+/g, ' ');

			if (name === 'fein') {
				validateFein(feinValue, name);
			}
			setBusinessInformation((prev: IBuisness_information) => ({
				...prev,
				[name || (key as string)]: value,
			}));
			setBusinessInformation((prev: IBuisness_information) => ({
				...prev,
				error: {
					[`error_${name}`]: '',
				},
			}));
		},
		[setBusinessInformation, validateFein]
	);

	const getCountryeDetails = useCallback(
		(value: string) => {
			return countryListResp.find(country => country?.name === value);
		},
		[countryListResp]
	);

	const selectedCountry = useMemo(() => {
		return getCountryeDetails(businessInformation?.country);
	}, [businessInformation?.country, getCountryeDetails]);

	useEffect(() => {
		if (
			selectedCountry &&
			selectedCountry?.kyb &&
			selectedCountry?.kyb?.states
		) {
			const stateLists = (selectedCountry?.states ?? []).map(state => ({
				label: formatValue(state.name ?? '--'),
				value: state.name,
			}));
			setStateList(stateLists);
		}
	}, [selectedCountry, setStateList]);

	const getCustomOptions = useCallback(
		({ children, innerProps }: CustomOptionDropdownProps) => {
			const value = getCountryeDetails(children);
			return (
				<div className="kyc-select-country__dropdown--option" {...innerProps}>
					<span className="kyc-select-country__dropdown--option-flag">
						{value?.emoji}
					</span>{' '}
					{children}
				</div>
			);
		},
		[getCountryeDetails]
	);

	const getOption = useCallback(
		(name: string): IOption[] => {
			if (name === 'country') {
				const countries = formattedCountries ?? [];
				if (
					// For these two businesses, I need to show all countries as global KYB is enabled for production.
					userBuisnessId === '63a58474654ed953f4893753' ||
					userBuisnessId === '66aa7a9ce1883a7b93a098e0' ||
					envHost === 'prod'
				) {
					return countries;
				}
				return countries[0] ? [countries[0]] : [];
			}
			return statesList ? statesList : [];
		},
		[formattedCountries, userBuisnessId, statesList, envHost]
	);

	const handleChangeSelect = useCallback(
		(event: any, name: string) => {
			setBusinessInformation((prev: IBuisness_information) => ({
				...prev,
				[name]: event.value,
			}));

			if (name === 'country') {
				const selectedCountry: CountryStateList = countryListResp.find(
					country => country.name === event.value
				) as CountryStateList;

				const stateLists = (selectedCountry.states ?? []).map(state => ({
					label: formatValue(state.name ?? '--'),
					value: state.name,
				}));

				if (stateLists.length === 0) {
					setBusinessInformation((prev: IBuisness_information) => {
						const updatedPrev = { ...prev };
						delete updatedPrev.state;
						return updatedPrev;
					});
				} else {
					setBusinessInformation((prev: IBuisness_information) => ({
						...prev,
						state: '',
					}));
				}
				setStateList(stateLists);

				if (businessInformation?.fein) {
					validateFein(businessInformation?.fein, 'fein');
				}
				trackEvents(EVENTS_TRACKING.KYB_COMPANY_COUNTRY_CHOSEN, {
					timestamp: timestamp,
					company_country: selectedCountry?.name ?? '',
				});
			}
		},
		[
			businessInformation.fein,
			countryListResp,
			setBusinessInformation,
			setStateList,
			validateFein,
			trackEvents,
		]
	);

	const getValue = useCallback(
		(name: string) => {
			if (!businessInformation[name]) {
				return { label: 'Select', value: '' };
			}
			return {
				label: (businessInformation[name] ?? 'Select')
					.replace(/_/g, ' ')
					.toLowerCase()
					.replace(/\b\w/g, (char: string) => char.toUpperCase()),
				value: businessInformation[name] ?? '',
			};
		},
		[businessInformation]
	);

	const renderInputs = useMemo(() => {
		return KYB_BUSINESS_VERIFICATION?.map((el, index: number) => {
			switch (el.type) {
				case 'text':
					return (
						<div
							key={`${el.name}__${index.toString()}`}
							className="kyb-details--col-1"
						>
							<Input
								isRequired={true} // Set isRequired to true for other text inputs
								label={el.label}
								inputType={el.type}
								placeholder={el.placeHolder}
								handleChange={handleChange}
								inputName={el.name}
								value={businessInformation[el.name]}
								key={el.name}
							/>
						</div>
					);
				case 'select':
					return (
						<div
							key={`${el.name}__${index.toString()}`}
							className="kyb-details--col-1"
						>
							<ReactDropdown
								options={getOption(el.name)}
								handleChangeSelect={event => handleChangeSelect(event, el.name)}
								isSearchable={true}
								optionsDropHeight={230}
								label={el.name}
								value={getValue(el.name)}
								placeholder={el.name}
								key={el.name}
								getCustomOptions={getCustomOptions}
								isRequired
							/>
						</div>
					);
				case 'stateSelect':
					return selectedCountry?.kyb?.states && statesList?.length ? (
						<div
							key={`${el.name}__${index.toString()}`}
							className="kyb-details--col-1"
						>
							<ReactDropdown
								options={getOption(el.name)}
								handleChangeSelect={event => handleChangeSelect(event, el.name)}
								isSearchable={true}
								label={el.name}
								value={getValue(el.name)}
								optionsDropHeight={230}
								placeholder={el.name}
								key={el.name}
								isRequired
							/>
						</div>
					) : (
						<></>
					);

				case 'fein':
					return businessInformation.country ? (
						<div className="kyb-details__dropdown__wrapper kyb-details--col-1">
							<div
								style={{
									width: '100%',
									height: '100%',
									display: el.name === 'fein' ? 'block' : 'none',
								}}
							>
								<Input
									inputType="text"
									label={`${el.label} ${KybCountryRegistration}`}
									inputName={el.name}
									placeholder={feinPlaceholder}
									handleChange={handleChange}
									value={businessInformation[el.name]}
									errorMessage={businessInformation.error?.error_fein}
									isError={!!businessInformation.error?.error_fein}
									isRequired={true}
									// handleKeyPress={handleFeinInputLimit}
									autoComplete="off"
									// onPaste={handleFeinInputLimit}
								/>
							</div>
						</div>
					) : (
						<></>
					);

				default:
					return <></>;
			}
		});
	}, [
		handleChange,
		businessInformation,
		getOption,
		getValue,
		getCustomOptions,
		KybCountryRegistration,
		feinPlaceholder,
		handleChangeSelect,
		selectedCountry?.kyb?.states,
		statesList?.length,
	]);
	return <>{renderInputs}</>;
};
