export function throttle<T extends (...args: any[]) => void>(
	callback: T,
	delay: number
): (...args: Parameters<T>) => void {
	let isThrottled = false;

	return (...args: Parameters<T>): void => {
		if (isThrottled) return;

		isThrottled = true;
		setTimeout(() => {
			isThrottled = false;
		}, delay);

		callback(...args);
	};
}
