export const EVENTS_TRACKING = {
	QR_DETAILS: 'qr-details',
	USERS_DATA: 'users-data',
	BIOMETRICS_COMPLETED: 'biometrics_completed',
	BIOMETRICS_TYPE_SELECTED: 'biometrics_type_selected',
	VERIFICATION_TYPE_SELECTED: 'verification_type_selected',
	ACCREDITATION_COMPLETED: 'accreditation_completed',
	ACCREDITATION_ANSWER_CHOSEN: 'accreditation_answer_chosen',
	ACCREDITATION_OUTCOME: 'accreditation_outcome',
	AGREEMENT_SIGNED: 'agreement_signed',
	VERIFICATION_SUCCESS: 'verification_success',
	VERIFICATION_PENDING_REVIEW: 'verification_pending_review',
	VERIFICATION_REJECTED: 'verification_rejected',
	KYC_COMPLETED: 'kyc_completed',
	KYC_DOCUMENT_CHOSEN: 'kyc_document_chosen',
	KYC_COUNTRY_CHOSEN: 'kyc_country_chosen',
	KYC_OUTCOME: 'kyc_outcome',
	KYB_COMPLETED: 'kyb_completed',
	KYB_COMPANY_COUNTRY_CHOSEN: 'kyb_company_country_chosen',
	KYB_OUTCOME: 'kyc_outcome',
	KYB_FORM_COMPLETED: 'kyb_form_completed',
	KYB_ENTITY_TYPE_CHOSEN: 'kyb_entity_type_chosen',
	QUESTIONNAIRE_COMPLETED: 'questionnaire_completed',
	QUESTIONNAIRE_ANSWER_CHOSEN: 'questionnaire_answer_chosen',
	VERIFICATION_COMPLETED: 'verification_completed_cap_web',
	VERIFICATION_STARTED: 'verification_started_cap_web',
};

export const timestamp = new Date().toISOString();
