import { FC } from 'react';
import { Loader } from '@storybook';

interface IQrLoader {
	description?: string; // Optional description text to display below the loader
}

export const QrLoader: FC<IQrLoader> = ({ description }) => {
	return (
		<div className="Qr__Success--footer_wrapper">
			{/* Circular loader to indicate loading progress */}
			<Loader type="circle" />

			{/* Conditionally render description text if provided */}
			{description && (
				<div className="Qr__Success--footer-text">{description}</div>
			)}
		</div>
	);
};
