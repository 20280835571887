/**
 * Checks if the current device is a desktop.
 *
 * The function evaluates the user agent string from the browser to identify whether
 * the device is likely a desktop. It uses a regular expression to detect common
 * mobile and tablet device identifiers. If none of these identifiers are present,
 * the function assumes the device is a desktop.
 *
 * @returns {boolean} - Returns true if the device is a desktop, false if it's a mobile or tablet.
 */
export function isDesktopDevice() {
	// Get the user agent string from the browser's navigator object
	const userAgent = navigator.userAgent;

	// Regular expression to detect common mobile and tablet device identifiers
	// This pattern checks for terms like 'Mobile', 'Android', 'iPhone', etc., in the user agent string
	const mobileRegex =
		/(Mobile|Android|iPhone|iPad|iPod|BlackBerry|webOS|Opera Mini|IEMobile|Windows Phone)/i;

	// If the user agent does not match any mobile or tablet devices, return true (indicating a desktop)
	// Otherwise, return false (indicating a mobile or tablet device)
	return !mobileRegex.test(userAgent);
}
