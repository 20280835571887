import { REACT_APP_LIQUIDITY_BUSINESSID } from 'envs';
import { useSharedVariables } from 'hooks';
import {
	SessionDetailsState,
	userBuisnessIdState,
} from 'hooks/use-next-step/stores';
import { useRecoilValue } from 'recoil';

export const useTrackEvents = () => {
	const userBuisnessId = useRecoilValue(userBuisnessIdState);
	const sessionDetail = useRecoilValue(SessionDetailsState);
	const { envHost } = useSharedVariables();
	const trackEvents = (eventName: string, properties: any) => {
		const isProd = /secure.simplici.io/gi.test(window.location.host);
		const businessID = sessionDetail?.businessId ?? '' ?? userBuisnessId;
		const isFromLiquidity =
			REACT_APP_LIQUIDITY_BUSINESSID !== businessID || !isProd;
		if (isFromLiquidity) return;
		try {
			(window as any).gtag('event', `${envHost}_${eventName}_sim`, {
				...properties,
			});
			(window as any).analytics.track(`${envHost}_${eventName}_sim`, {
				...properties,
			});
		} catch (error) {
			return;
		}
	};

	return { trackEvents };
};
